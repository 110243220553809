<template>
  <div class="project-scopes">
    <div class="project-scopes__content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import changeSelectPage from '@/mixin/changeSelectPage'
export default {
  name: 'Index',
  mixins: [changeSelectPage],
}
</script>

<style lang="scss">
@import 'style';
</style>
